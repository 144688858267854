/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
@import "./assets/styles/styles.scss";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

.mat-select-panel {
  max-width: 310px !important;
}

.mat-sort-header-content
{
  text-align: center !important;
}
.mat-form-field-appearance-fill .mat-form-field-subscript-wrapper{
  padding:0px !important;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

$base-color: #407ADD;

.search-container {
  margin-top: 20px;
  width: 100%;
}

.row {
  display: block;
  margin-left: 30px;
}

.col {
  display: inline-block;
}

.mat-form-field {
  &__300 {
    width: 300px;
    max-width: 300px !important;
  }

  &__500 {
    width: 500px;
    max-width: 500px !important;
  }

  &__400 {
    width: 400px;
    max-width: 400px !important;
  }

  &__180 {
    width: 180px;
    max-width: 180px !important;
  }

  &__210 {
    width: 210px;
    max-width: 210px !important;
  }

  &__160 {
    width: 160px;
    max-width: 160px !important;
  }
}

.label-name {
  display: block;
}

.button-container {
  position: relative;
  left: 10px;
  top: -50px;
  width: 100px;
}

.container {
    width: calc(100% - 30px);
    margin-left: 15px;
}

.table-container {
  width: 100%;
  overflow-x: hidden;
}

.search-button {
  display: inline-block;
  top: -7px;
  position: relative;
  margin-left: 20px;
}

.search-block {
  position: relative;
  margin-left: 2%;
  margin-right: 5%;
  padding: 0;

  .search-option-block {
    display: inline-block;
    padding: 0 5px 10px 5px;
    max-height: 50px;

    &.search-option-button {
      min-width: 150px;
      transform: translateY(-10%);
      padding-top: 10px;

      button {
        width: 100%;
        height: 50px;
        left: 67%;
      }
    }
  }
}

.custom-header {
  margin-top: 80px;
  text-align: center;
}

.save-button-container {
  position: relative;
  margin-left: 90%;
  margin-top: 50px;
}

tr td {
  outline: none;
}

.sticky-table-container {
  max-height: calc(100vh - 350px);
  margin-bottom: 20px;
  overflow: auto;
}

th {
  padding: 0 10px;
}

td {
  padding: 0 10px;
}

::ng-deep th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  padding: 0 20px !important;
}

table {
  cursor: pointer;
}

//.mat-card {
//    width: 87%;
//    margin: 0 0 20px 50px;
//    //border: 1px solid black;
//    padding-bottom: 0;
//}

// html, html * {
//     color: white !important;
//     background-color: black !important;
// }

// CUSTOM SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #969696;
}

$loader-size: 80px;
$loader-weight: 8px;

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: transparentize(#fff, 0.7);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: $loader-size;
    height: $loader-size;
    border-radius: 100%;
    background: transparent;
    border: $loader-weight solid color($base-color, 0.75);
    border-top: $loader-weight solid $base-color;
    animation: loader-anim 1s linear infinite;
  }
}

@keyframes loader-anim {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// .mat-option-text {
//   display: inline-block;
//   flex-grow: 1;
//   overflow: auto !important;
//   text-overflow: unset !important;
// }

.mat-select-search-panel {
  min-width: fit-content !important;
}
.mat-select-search-inner {
  width: 100% !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336 !important;
}


.subscription-container {
.mat-radio-group.bussiness-type{
  display: flex;
}
  .radio-button-container{ .mat-radio-container {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  bottom: 97px !important;
  left: 106px !important;

}

}
.mat-radio-button.radio-button-container.mat-accent {
  // border: solid 3px #407ADD;
  transform: translateY(0%);
  transition: transform 0.5s;
}
.mat-radio-button.radio-button-container.mat-accent.mat-radio-checked{
  transform: translateY(-10%);

}

}

.inv-man-dialog {
    .mat-dialog-container {
        padding: 0;
    }
}

.tooltip-list {
  white-space: pre !important;
  max-width: unset !important;
  font-size: 14px !important;
}
.custom-mat-select-search-panel {
  /* allow absolute positioning relative to outer options container */
  transform: none !important;
  max-height: 240px !important;

}