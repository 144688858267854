@mixin label-theme($ecommin-app-theme) {
    .label-name-text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;        
        color: #000000;
        display: block;
    }
}
  