@import "ecommin-theme-theme";


::ng-deep .mat-error {
    color: red;
}

.mat-error {
    color: #f44336 !important;
}

.mat-form-field {
    min-height: 60px;
}

.required {
    color: #f44336 !important;
}

.cdk-overlay-pane {
    &.mat-tooltip-panel {
        .mat-tooltip {
            color: white;
            margin-top: -30px !important
        }
    }
}
.so-search-box > div{
    width: 436px !important;
  }