@mixin input-theme($ecommin-app-theme) {
  .mat-search-input {
    .mat-form-field-flex {
      display: flex;
      align-items: center;
      //padding: 0!important;
      padding: 4px 0 !important;
      border: 1px solid #c6c6c6;
      background-color: #ffff !important;
      border-radius: inherit !important;
      box-shadow: 1px 1px 0px 0px #c6c6c6;
    }
    .mat-form-field-prefix {
      margin: 4px 12px 0 12px;
      color: #212121;
    }
    .mat-form-field-underline {
      display: none;
    }
  }

  .mat-text-input {
    ::ng-deep .mat-form-field-flex > .mat-form-field-infix {
      padding: 0.4em 0px !important;
    }
    ::ng-deep .mat-form-field-label-wrapper {
      top: -1.5em;
    }

    ::ng-deep
      .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
      .mat-form-field-label {
      transform: translateY(-1.1em) scale(0.75);
      width: 133.33333%;
    }
  }
}
