@mixin table-theme($ecommin-app-theme) {
  table {
    border: 1px solid #C4C4C4;
    // margin: 20px 0;
    box-shadow: none !important;
  }

  th.mat-header-cell {
    text-align: center !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    font-style: normal;
    color: #fff;
    background-color: #407ADD;
  }

  td.mat-cell {
    background-color: #fff;

    &.gray {
      background-color: #F6F6F6;
    }
    padding: 0 20px !important;
    text-align: center  !important;
  }

  .mat-row:hover {
    background-color: rgb(124, 124, 124);
  }

  ::selection {
    color: red;
  }

  tr td {
    outline: none;
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    padding: 0 20px !important;
 //   text-align: left !important;  // just for some time
  }

  .icon-status {
    top: 7px;
    position: relative;

    &.incomplete {
      color: red;
    }

    &.complete {
      color: green;
    }
  }
}
