@mixin dialog-popup-theme($ecommin-app-theme) {
  .popup-container {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .popup-close-button {
        cursor: pointer;
      }

      .popup-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
      }
    }

    .content {
      .row {
        margin-left: 0;

        .subtitle {
          margin: 20px 0;
          .subtitle-text {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
    }
  }
}
