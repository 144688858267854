@mixin input-container-theme($ecommin-app-theme) {
    .input-container {
        display: block;
        margin: 10px 20px 10px 0px;
        width: 100%;

        &._300 {
            width: 300px;
        }

        &._500 {
            width: 500px;
        }

        &._400 {
            width: 400px;
        }

        &._180 {
            width: 180px;
        }

        &._220 {
            width: 220px;
        }

        &._240 {
            width: 240px;
        }

        &.inline {
            display: inline-block;
        }
    }
}
