@mixin custom-button-theme() {
    .button-item {
        height: 48px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 24px;
        border-radius: 4px;
        border: none;

        .text {
            font-size: 16px;
            font-weight: 500;
            color: #FFF;
    
            &.disabled {
                color: #4A5165;
            }
        }

        &.active {
            background-color: #407ADD;
            cursor: pointer;
        }

        &.disabled {
            background-color: #DCDCDC;
            cursor: not-allowed;
        }        
    }
}

